import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import "./layout.css"
import "./layout.scss"
import "../../scss/styles.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer>
        <div className="component-wrapper footer-block">
          <p className="text-center">
            © {new Date().getFullYear()}, Dennisworks, LLC
          </p>
        </div>
      </footer>
    </> 
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
