import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../../images/dw_header_logo_white.png'
import IdentityModal, { useIdentityContext } from "react-netlify-identity-widget"
import "react-netlify-identity-widget/styles.css" // delete if you want to bring your own CSS
import "./header.scss"

const Header = ({ siteTitle }) => { 

  const identity = useIdentityContext()
  const [dialog, setDialog] = React.useState(false)
  const name = (identity && identity.user && identity.user.user_metadata && identity.user.user_metadata.name) || "NoName"

  console.log(JSON.stringify(identity))

  const isLoggedIn = identity && identity.isLoggedIn

  return (
    <header>
      <div className="component-wrapper header-component">
        <div class="row">
          <nav className="navbar dw-navbar">
            <div className="navbar-block brand-block">
              <Link to="/" className="navbar-brand">
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </div>
            <div className="navbar-block nav-links-block">
              <Link to="/about" className="nav-link dw-navbar-link">About</Link>
              <Link to="/projects" className="nav-link dw-navbar-link">Projects</Link>
            </div>
            <div className="navbar-block navbar-login-btn-block">
              <button className="btn dw-navbar-link login-modal-btn" onClick={() => setDialog(true)}>
                {isLoggedIn ? `${name}` : "LOG IN"}
              </button>
            </div>
          </nav>
        </div>
      </div>
      <IdentityModal showDialog={dialog} onCloseDialog={() => setDialog(false)} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Dennisworks`,
}

export default Header
